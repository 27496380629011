<template>
  <div class="home">
    <Section light>
        <h1>Apply</h1>
        <v-col class="px-4" cols="12">
          <p>
            Our 2024-2025 season application cycle is now open!
            If you are interested in joining the team, fill out
            <a href="https://forms.gle/WVnmvGnY2TiTq5oR6"> this short form </a>
            to apply.
          </p>
          <div class="button-container">
            <button class="apply-button">
              <a href="https://forms.gle/WVnmvGnY2TiTq5oR6" target="_blank">Apply Here</a>
            </button>
          </div>
        </v-col>
        <div class="page-fill"></div>
    </Section>
  </div>
</template>

<script>
import Section from '@/components/Section.vue';

export default {
  name: 'Apply',
  components: {
    Section,
  },
};
</script>

<style scoped>
h1 {
  text-align: center;
}
h2 {
  text-align: center;
}
a {
  color: white;
}
a:visited {
  color: white;
}
a:link {
  color: white;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.apply-button {
  background-color: #1f1a2e;
  border: 2px solid #1f1a2e;
  color: white;
  padding: 15px 32px;
  text-align: center;
  font-size: 32px;
  border-radius: 15px;
  cursor: pointer;
  text-decoration: none;
  transition: 0.3s;
}

.apply-button:hover {
  background-color: white;
  color: #1f1a2e;
}

.apply-button a {
  color: inherit;
  text-decoration: none;
}

.page-fill {
  height: 85vh;
}
</style>
